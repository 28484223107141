<template>
  <div class="loan_item">
    <div class="inner inner1">
      <!-- <h4>Ventajas</h4> -->
      <div class="content">
        <div class="left_cell">
          <!-- <div class="cell_item">
            <h3>Préstamo rápido</h3>
            <p>Obtener un préstamo mediante la solicitud si usted envía su información</p>
          </div>
          <div class="cell_item">
            <h3>No hay garantía</h3>
            <p>los ciudadanos colombianos que cumplan su edad pueden solicitarlo y no molestar a la gente con él</p>
          </div>
          <div class="cell_item">
            <h3>Máximo</h3>
            <p>Le proporcionaremos un préstamo de hasta $750,000 basado en su capacidad de pago</p>
          </div>
          <div class="cell_item">
            <h3>Tranquilidad</h3>
            <p>Simple y Rápido Sin Operaciones Complejas, Le Permite Pedir Dinero Prestado Simplemente, Reembolsar Rápidamente Mantenga Crédito para Mayores Límites de Préstamo”</p>
          </div> -->
          <h1>About Us</h1>
          <p class="inner1_text">UltraNaira is an online finance application where users can apply
            for personal loans up to ₱ 30,000 anytime and anywhere. It
            provides flexible and good loan services. It offers complete
            online money without any mortgage through secure platforms.
          </p>
          <p class="inner1_text">
            Our company provides instant financial loans and we provide
            personal loan solutions. We not only support your personal
            consumption (such as personal medical care, insurance
            premiums, investment, wedding expenses, travel, transportation,
            education expenses, etc.), but also help you flexibly obtain loans
            and solve your money needs through innovative technological methods.
          </p>
        </div>
        <!-- <img src="@/assets/images/loanImg/loan_bg.png" alt=""> -->
        <div class="right_cell">
          <img src="~@/assets/images/loanImg/about_us.png">
          <!-- <div class="cell_item">
            <h3>Acumulación de crédito</h3>
            <p>Mantenga su crédito y obtener más préstamos y apoyo</p>
          </div>
          <div class="cell_item">
            <h3>Seguridad</h3>
            <p>Procese rápidamente su préstamo y envíelo automáticamente a su cuenta bancaria dentro de las 24 horas, hasta 5 minutos</p>
          </div>
          <div class="cell_item">
            <h3>Servicios profesionales</h3>
            <p>El reembolso es súper simple, porque después de pedir prestado, usted será servido por un consultor profesional para ayudarle a resolver sus problemas de reembolso, reembolso sin preocupaciones, no se preocupe por no reembolso que resulta en retraso</p>
          </div> -->
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <div class="inner5-bg">
        <h3>Values</h3>
      </div>
      <div class="inner5_item">
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/about_inner5-1.png" alt="">
            <div class="item_info">
              <h2>open</h2>
              <p>Respect and tolerance, empathy</p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/about_inner5-2.png" alt="">
            <div class="item_info">
              <h2>cooperation</h2>
              <p>Collaborate with each other and continue to evolve</p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/about_inner5-3.png" alt="">
            <div class="item_info">
              <h2>create</h2>
              <p>Beyond innovation, explore the future</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <!-- <h4>Sobre nosotros</h4> -->
      <div class="content">
        <div class="left_cell">
          <h1>Mission</h1>
          <p>Nuestro objetivo es ofrecer a los clientes una plataforma financiera flexible, sencilla, segura, rápida y sin preocupaciones. No tenemos muchos umbrales y solo intentamos hacer clic en nuestro botón de aplicación para experimentar nuestro servicio.</p>
        </div>
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner2.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="content">
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner3.png" alt="">
        </div>
        <div class="left_cell">
          <h1>Vision</h1>
          <p>To solve the problem of money use and improve the quality of life for everyone, we are committed to providing the best financial technology services to society.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #36665A;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      .inner1_text{
        color: #333;
        line-height: 25px;
        margin: 40px 0;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #36665A;
      }
    }
  }
  .inner1{
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        // display: flex;
        // justify-content: center;
      }
      .right_cell{
        img{
          margin: 25px auto !important;
          width: 380px;
          height: 380px;
          display: block;
        }
      }
    }
  }
  .inner2{
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
        justify-content: center;
      }
      img{
        margin: 25px auto;
        width: 300px;
        height: 300px;
        // display: block;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        p{
          font-size: 12px;
          line-height: 25px;
          color: black;
        }
      }
    }
  }

  .inner3{
    margin-bottom: 50px;
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
        justify-content: center;
      }
      img{
        margin: 25px auto;
        width: 300px;
        height: 300px;
        // display: block;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        p{
          font-size: 12px;
          line-height: 25px;
          color: black;
        }
      }
    }
  }

  .inner5{
    padding: 0 0 70px 0;
    .inner5-bg{
      width: 100%;
      height: 250px;
      background: linear-gradient(271deg, #9D409B 0%, #160033 100%);
      // padding: 50px 250px;
    }
    h2{
      line-height: 30px;
      margin-bottom: 20px;
    }
    h3{
      font-size: 40px;
      color: white;
      text-align: center;
      line-height:150px;
    }
    .inner5_item{
      // width: 100%;
      display: flex;
      justify-content: space-between;
      margin: -100px 0 0;
      padding: 0px calc((100% - 1100px) / 2) 0;
      font-size: 14px;
      text-align: center;
      line-height: 16px;
      .content{
        flex: 1;
        background: #fff;
        padding: 40px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 25px;
        box-shadow: 0 0 5px #999;
        img{
          // margin-top: 50px;
          width: 100px;
        }
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .cell_item{
          width: 100% !important;
          height: 100px;
        }
        img{
          margin-top: 0px;
          width: 300px !important;
          height: 300px !important;
          margin: 0 auto ;
        }
      }
    }
    .inner2{
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          p{
            padding: 0 !important;
            width: 100% !important;
            margin:  15px 0;
          }
        }
        img{
          margin: -20px 0px 50px;
        }
      }
    }
    .inner3{
      padding-top: 20px;
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          p{
            padding: 0 !important;
            width: 100% !important;
            margin:  15px 0;
          }
        }
        img{
          margin: -20px 0px 50px;
        }
      }
    }
  }
  .inner5{
    padding: 0 0 100px 0  !important;
    min-width: calc(100vw - 40px) !important;
    width: calc(100vw - 40px) !important;
    margin: 0 auto !important;
    h3{
      font-size: 18px;
    }
    .inner5_item{
      margin:  -100px 0px !important;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .content{
        margin: 20px 25px !important;
      }
    }
  }
}
</style>
